<template>
  <a-card :border="false">
    <a-result :status="privacyDetail.owner_setting.contact_phone?'success':'info'"
      :title="privacyDetail.owner_setting.contact_phone?'已设置':'未设置'"
      :sub-title="'该小程序'+(privacyDetail.owner_setting.contact_phone?'已设置':'未设置')+'隐私保护协议'">
      <template #extra>
        <a-button key="console" type="primary" v-has:privacyAgreement='seting'>
          {{privacyDetail.owner_setting.contact_phone?'已设置':'未设置'}}</a-button>
      </template>
    </a-result>
  </a-card>
</template>
<script>
  import { privacySetting, getPrivacy } from "@/api/appletCode";
  import { useRoute } from 'vue-router';
  import { $iscode } from '../../../../utils/app';
  import { onMounted, ref } from '@vue/runtime-core';
  export default {
    setup() {
      const $route = useRoute()
      const privacyDetail = ref({ owner_setting: {} })
      const initData = async () => {
        let result = await getPrivacy({ brand_id: $route.query.brand_id, appid: $route.query.app_id,miniapp_type:$route.query.miniapp_type?$route.query.miniapp_type:""  }).then(res => res.data).catch(error => error)
        privacyDetail.value = result.data
      }
      const seting = async () => {
        let result = await privacySetting({ brand_id: $route.query.brand_id, appid: $route.query.app_id,miniapp_type:$route.query.miniapp_type?$route.query.miniapp_type:""  }).then(res => res.data).catch(error => error)
        $iscode(result, true)
        initData()
      }
      onMounted(() => {
        initData()
      })
      return {
        seting,
        privacyDetail
      }

    }
  };
</script>